export default function parseFullDate(input, format) {
  if (input !== "" && typeof input === "string") {
    format = format || "yyyy-mm-dd hh:mn:ss"; // default format
    var parts = input.match(/(\d+)/g),
      i = 0,
      fmt = {};
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm|hh|mn|ss)/g, function(part) {
      fmt[part] = i++;
    });
    return new Date(parts[fmt['yyyy']], parts[fmt['mm']]-1, parts[fmt['dd']], parts[fmt['hh']], parts[fmt['mn']], parts[fmt['ss']] );
  }
  return "Invalid Date";
}