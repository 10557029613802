export default function getDateOnlyFormat(currentDate) {
  // Get the date, month, and year
  const year = currentDate.getFullYear();
  // JavaScript months are 0-indexed, so we add 1 to get the correct month
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  // Format the date as "YYYY-MM-DD"
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

	return formattedDate;
}
