/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import {FormGroup, Input, Row, Col,InputGroup, InputGroupText, InputGroupAddon, Form, Alert} from 'reactstrap';
import VInput from 'react-validation/build/input';
import VForm from 'react-validation/build/form';
import Textarea from 'react-validation/build/textarea';
import {httpClient} from '../../../../tools/HttpClient';
import {dependencies} from '../../../../tools/Dependencies';
import states from './countryState/statesArr';
import country_arr from './countryState/countrysArr';
import cityStateDict from './countryState/cityArr';
import GlobalImg from '../../../../assets/Icons/globalIcon.png';
const required = (value) => {
  if (!value.toString().trim().length) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return <div className="alert alert-danger fieldErrorMessage" role="alert"><i className="fa fa-exclamation-circle"></i> This field is required.</div>;
  }
};
class BusinessInfo extends Component{
  state={
    publicError:'',
    flowData:''
  }
  componentDidMount=()=>{
    let config ={
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "Accept":"application/json",
       "Accept-Language":dependencies.getFromLocalStorage("lang")
      },
    }
    httpClient.get(
      '/api/auth/sector-industry', 
      config,
      (resp)=>{
       this.setState({flowData:resp.data});
      },
       (error)=>{ console.error(error);}
    )
  }
renderStates=()=>{
      if(this.props.country === ''){
        return (
            <select className="form-control" id="state" name="state">
                <option value=''>Select Country First</option>
            </select>
        )
      }else{
        let currentStatesArr = states[1].split("|");
        return (
            <select className="form-control" id="state" name="state" 
                value={this.props.state}          
                onChange={this.props.handleStateChange}
                
            >
                <option value=''>{this.props.Text.Step.businessInfoQu5placeholder1}</option>
                {currentStatesArr.map((ele, index)=>{
                    return <option key={index} value={index + 1} >{ele}</option>
                })}
                <option value={-1}>--Other--</option>
            </select>
        )
      }
  }
renderCity=()=>{
    const{
    state,
    city,
    cityName,
    handelCityChange,
    country,
     handleCityChange2}=this.props;
    let cityTextInput;
    if(state === ''){
        cityTextInput = <Input type="text" className="form-control cardInput" name="c_pan_U" placeholder="Select State First" required disabled={true} value={cityName} onChange={handleCityChange2} validations={[required]} />;
    }
    else{
        cityTextInput = <Input type="text" className="form-control cardInput" name="c_pan_U" placeholder="City" required disabled={false} value={cityName} onChange={handleCityChange2} validations={[required]} />;
    }
     if(country_arr[country - 1] === 'Egypt'){
        if(state === ''){
            return (
                <select className="form-control" id="city" name="city"
                    placeholder= {this.props.Text.Step.businessInfoQu5placeholder2}
                >
                    <option value="" disabled>{this.props.Text.Step.businessInfoQu5placeholder2}</option>
                </select>
            )
        }else if(state === '-1'){
            return cityTextInput
        }else{
            //get city array for current state
            let currentState = states[1].split("|")[state -1];
            let cityArrForCurrentState = [];
            for(let i=0; i < Object.keys(cityStateDict).length; i++){
                if(cityStateDict[Object.keys(cityStateDict)[i]] === currentState){
                    cityArrForCurrentState.push(Object.keys(cityStateDict)[i]);
                }
            }
            if(city === '-1'){  
                return (
                    <div>
                        <select className="form-control" id="city" name="city" 
                            value={city} 
                            onChange={handelCityChange}
                            placeholder= {this.props.Text.Step.businessInfoQu5placeholder2}
                            >
                            <option value=''disabled>{this.props.Text.Step.businessInfoQu5placeholder2}</option>
                            {cityArrForCurrentState.map((ele, index)=>{
                             
                                return <option key={index} value={ele}>{ele}</option>
                            })}
                            <option value={-1}>--Other--</option>
                        </select>
                        {cityTextInput}
                    </div>
                )
            }else{
                return (
                    <select className="form-control" id="city" name="city" 
                        value={cityName} 
                        onChange={handelCityChange}
                        placeholder= {this.props.Text.Step.businessInfoQu5placeholder2}
                    >
                        <option value='' disabled>{this.props.Text.Step.businessInfoQu5placeholder2}</option>
                        {cityArrForCurrentState.map((ele)=>{
                            return <option key={ele} value={ele}>{ele}</option>
                        })}
                        <option value={-1}>--Other--</option>
                    </select>
                )
            }
          }
      }else{
        return cityTextInput
      }
    }
    validationCheck=()=>{
      if (typeof this.props.sectorOption == 'undefined' || !this.props.sectorOption) {
        return <Alert>A gender selection is required</Alert>
      }
    }
renderSectors=()=>{
let sectors = [];
const{sectorOption,handleSectorChange,Text}=this.props
const{flowData}=this.state
for(let i = 0; i < flowData.length; i++){
  // console.log(i)
   if(!sectors.includes((flowData[i]).title)){
      sectors.push(flowData[i]);
     
        }
      }
        return (
            <select className="form-control" id="sector" name="sector" 
                value={sectorOption} 
                onChange={handleSectorChange}
                required
                onClick={this.validationCheck}
            >
                <option value=''>{Text.Step.businessInfoQu2placeholder}</option>
                {sectors.map((ele, index)=>{
                    return <option key={index} value={ele.id} >{ele.title}</option>
                })}
            </select>
        )
  }
  renderIndustry=()=>{
    let acceptOptions;
    const{flowData}=this.state
    const{industryOption,sectorOption,handleIndustryChange,Text}=this.props
    if (industryOption === '') {
      acceptOptions = <VInput type="text" className="form-control cardInput" name="c_pan_U" placeholder={this.props.Text.placeholderSector} required disabled={true} value='' onChange={handleIndustryChange} validations={[required]} />;
  }
  if(sectorOption === '-1'){
    return (
        <select className="form-control" id="Industry" name="Industry"
            required
            placeholder= {Text.Step.businessInfoQu3placeholder}

        >
            <option value="" >{Text.Step.businessInfoQu3placeholder}</option>
        </select>
    )
}else{
  if(this.props.sectorOption !== ''){
    let currentIndustryValue=[];
    let CurrentIndusrtyForSector = [];
    const{sectorOption}=this.props
    for (let i = 0; i < flowData.length; i++){
      if (flowData[i].id == sectorOption) {
          CurrentIndusrtyForSector.push(flowData[i].industries);
          
        }
    }
    CurrentIndusrtyForSector.forEach(element => {
     
      element.map(ele => 
      currentIndustryValue.push(ele)

       )   });
  return(
    <select className="form-control" id="state" name="state" 
                value={this.props.industryOption} 
                onChange={this.props.handleIndustryChange}
                required
            >
                <option value=''>{this.props.Text.Step.businessInfoQu3placeholder}</option>
                {currentIndustryValue.map((ele, index)=>{
                    return <option key={index} value={ele.id} >{ele.title}</option>
                })}
            </select>
  )
  }
}
  return acceptOptions;
}
  render(){
    const{Text,brandName,brandNameChange,description,descriptionChange,street,stateName,handleStateChange2,
      streetChange,buildNum,buildNumChange,floor,floorChange,website,instagram,instagramChange,websiteChange,facebook,facebookChange,HandleQaSubmit,textSubmit,HandleStepSubmit
    } = this.props
return(
  <VForm onSubmit={HandleQaSubmit}>
 <div className="Business-info-container">
<FormGroup>
<legend className="businessInfo-legand">{Text.businessModalHeader}</legend>
</FormGroup>
<Row>
 <Col lg="12" md="12" xs="12" sm="12">
 <div className="brand-name-container">
     <p>{Text.Step.businessInfoQu1}</p>
     <VInput type="text" 
     className="form-control"
     name="text" 
     value ={brandName} 
     placeholder={Text.Step.businessInfoQu1placeholder} 
    onChange={brandNameChange}
    validations={[required]}
     />
      </div>
 </Col>
 <Col lg="6" md="12" xs="12" sm="12">
 <div className="industry-container">
     <p>{Text.Step.businessInfoQu2}</p>
     {console.log("test")}
   {this.renderSectors()}
       </div>
       <div className="industry-container">
       <p>{Text.Step.businessInfoQu3}</p>
  {this.renderIndustry()}
       </div>
 </Col>
 <Col lg="6" md="12" xs="12" sm="12">
   <div className="industry-container">
     <p>{Text.Step.businessInfoQu4}</p>
     <Textarea type="textarea" name="text" 
     value ={description}
      placeholder={Text.Step.businessInfoQu4placeholder}  
      onChange={descriptionChange}
    className="form-control business-textarea"
    validations={[required]}
    />
     </div>
 </Col>
 </Row>
 <Row>
 <Col lg="6" md="12" xs="12" sm="12">
   <div className="address-container">
<p>{Text.Step.businessInfoQu5}</p>
<Row>
  <Col lg="6" md="6" xs="12" sm="12">
  <div className="address">
  {this.renderStates()}
  </div>
  <div className="address">
  {this.props.state === "-1" &&
  <Input type="text" className="form-control cardInput" name="c_pan_U" placeholder="*Other state"
   value={stateName}
   onChange={handleStateChange2}
   /> }
  </div>
  </Col>
  <Col lg="6" md="6" xs="12" sm="12">
  <div className="address">
  <div className="form-group">
  {this.renderCity()}
  </div>
  </div>
  </Col>
  <Col md="12" lg="12" xs="12" sm="12">
  <div className="address">
  <Input type="text" name="street" 
  placeholder={Text.Step.businessInfoQu5placeholder3} 
  value={street}
  onChange={streetChange} />
  </div>
  </Col>
  <Col lg="6" md="6" xs="12" sm="12">
  <div className="address">
  <Input type="number" name="buildNum" 
  placeholder={Text.Step.businessInfoQu5placeholder4} 
  value={buildNum} 
  onChange={buildNumChange}/>
  </div>
  </Col>
  <Col lg="6" md="6" xs="12" sm="12">
  <div className="address">
  <Input type="number" name="ccmonth" 
  placeholder={Text.Step.businessInfoQu5placeholder5} 
  value={floor} 
  onChange={floorChange}/>
  </div>
  </Col>
</Row>
   </div>
 </Col>
 <Col lg="6" md="12" xs="12" sm="12">
   <div className="address-container">
<p>{Text.Step.businessInfoQu6}</p>
   <Row>
     <Col md="12" xs="12" sm="12">
     <div className="address">
     <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText className="webIcon"><img src={GlobalImg} alt=""/></InputGroupText>
        </InputGroupAddon>
        <Input type="text" value={website} onChange={websiteChange} placeholder="https://www.mywebsitepage.com"/>
      </InputGroup>
       </div>
     </Col>
     <Col md="12" xs="12" sm="12">
     <div className="address">
     <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText className="webIcon"><i className="fa fa-facebook"/></InputGroupText>
        </InputGroupAddon>
        <Input type="text" value={facebook} onChange={facebookChange} placeholder="https://www.facebook.com/myfacebookpage"/>
      </InputGroup>
       </div>
       </Col>
     <Col md="12" xs="12" sm="12"> 
     <div className="address">
     <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText className="webIcon"><i className="fa fa-instagram"/></InputGroupText>
        </InputGroupAddon>
        <Input type="text" value={instagram} onChange={instagramChange} placeholder="https://www.instagram.com/myinstagrampage"/>
      </InputGroup>
     </div>
     </Col>
   </Row>
   </div>
 </Col>
 {this.props.error && <div className="ErrorMessage">{this.props.errorMessage} Is not a valid .</div>}
</Row>
{/* <button type="reset" className="skip-btn" onClick={HandleStepSubmit}>{Text.skipBtn}</button> */}
</div>
</VForm>
)
}
}
export default BusinessInfo;
