import React, { Component } from "react";
import { dependencies } from "./../../tools/Dependencies";
import textFile from "./text.json";
import "./CopyToClipboard.scss";

class CopyToClipboard extends Component {
  state = {
    linkCopied: false
  };

  render() {
    const Text = textFile[dependencies.getFromLocalStorage("lang")];

    return (
      <button
        onClick={e => {
          e.preventDefault();
          this.setState(
            { linkCopied: dependencies.copyToClipboard(this.props.text) },
            () => {
              setTimeout(() => {
                this.setState({ linkCopied: false });
              }, 500);
            }
          );
        }}
        className="copyBtn"
      >
        <span className="copyText">
          {this.state.linkCopied ? (
            <>
              <i className="fa fa-check" /> {Text.copied}
            </>
          ) : (
            <>
              <i className="fa fa-clone" /> {Text.copy}
            </>
          )}
        </span>
      </button>
    );
  }
}
export default CopyToClipboard;
