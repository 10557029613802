import { addToLocalStorage, getFromLocalStorage, deleteFromLocalStorage } from "./dependencies/localStorageHandlers";
import copyToClipboard from "./dependencies/copyToClipboard";
import getParams from "./dependencies/getParams";
import numberWithCommas from "./dependencies/numberWithCommas";
import currencyName from "./dependencies/currencyName";
import integrationsName from "./dependencies/integrationsName";
import paymentTypeName from "./dependencies/paymentTypeName";
import paymentSolutionsName from "./dependencies/paymentSolutionsName";
import statusName from "./dependencies/statusName";
import bsMerchantStatus from "./dependencies/bsMerchantStatus";
import getAccountPermNames from "./dependencies/getAccountPermNames";
import permsName from "./dependencies/permsName";
import monthName from "./dependencies/monthName";
import parseDate from "./dependencies/parseDate";
import parseReachCustomDate from "./dependencies/parseReachCustomDate";
import getDateOnlyFormat from "./dependencies/getDateOnlyFormat";
import getNextDayDate from "./dependencies/getNextDayDate";
import parseFullDate from "./dependencies/parseFullDate";

class Dependencies {
    
    addToLocalStorage = addToLocalStorage;
    getFromLocalStorage = getFromLocalStorage;
    deleteFromLocalStorage = deleteFromLocalStorage;
    copyToClipboard = copyToClipboard;
    getParams = getParams;
    numberWithCommas = numberWithCommas;
    currencyName = currencyName;
    integrationsName = integrationsName;
    paymentTypeName = paymentTypeName;
    paymentSolutionsName = paymentSolutionsName;
    statusName = statusName;
    bsMerchantStatus = bsMerchantStatus;
    getAccountPermNames = getAccountPermNames;
    permsName= permsName;
    monthName = monthName;
    parseDate = parseDate;
    parseReachCustomDate = parseReachCustomDate;
    getDateOnlyFormat = getDateOnlyFormat;
    getNextDayDate = getNextDayDate;
    parseFullDate = parseFullDate;
    getPermsIds(namesList){
        let idsArr = [];
        var keys = this.getFromLocalStorage("permNames");
        namesList.map( name => {
            Object.keys(keys).map(id =>{
                if(name === keys[id]){
                    idsArr.push(id);
                }
            });
        });
        return idsArr
    }
    pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }
    IeDateFormate( input_date ){
        return input_date.slice(0, 19)
    }
    custom_date_format( input_date ){
        if(!input_date || input_date == "-"){
            return " - "
        }else{
            var d = this.parseFullDate(this.IeDateFormate(input_date));
            // if(dependencies.getFromLocalStorage("acq_partner") == true && dependencies.getFromLocalStorage("partner_time_zone") !== undefined){
            //     let h = this.getTimeZoneOffset(dependencies.getFromLocalStorage("partner_time_zone")) - this.getTimeZoneOffset('Africa/Cairo');
            //     d = new Date(d.getTime() + (h*60*60*1000))
            //     //convert from local zone to selected zone
            //     //d = new Date(d.toLocaleString("en-EG", {timeZone: dependencies.getFromLocalStorage("partner_time_zone")}))
            // }
            var output_time = d.toLocaleTimeString().toUpperCase().replace(/([\d]+:[\d]+):[\d]+(\s\w+)/g, "$1$2");
            var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            if( this.getFromLocalStorage("lang") === "ar" ){
            month = ['يناير','فبراير','مارس','ابريل','مايو','يونيو','يوليو','أغسطس','سبتمبر','أكتوبر','نوفمبر','ديسمبر'];
                output_time = output_time.replace(/am/g,"صباحا");
                output_time = output_time.replace(/pm/g,"مساءً");
            }
            var output_date =  d.getDate() +" "+ month[d.getMonth()]  +" "+ d.getFullYear() + ', ' + output_time;
            
            //return d.toISOString().split('T')[0]
            return output_date
        }
    }
    normal_date_format_add_hours( input_date , hours ){
        var d = this.parseFullDate(input_date);
        // if(dependencies.getFromLocalStorage("acq_partner") == true && dependencies.getFromLocalStorage("partner_time_zone") !== undefined){
        //     let h = this.getTimeZoneOffset(dependencies.getFromLocalStorage("partner_time_zone")) - this.getTimeZoneOffset('Africa/Cairo');
        //     h = h + hours;
        //     d = new Date(d.getTime() + (h*60*60*1000))
        //     //convert from local zone to selected zone
        //     //d = new Date(d.toLocaleString("en-EG", {timeZone: dependencies.getFromLocalStorage("partner_time_zone")}))
        // }else{
            let h = hours;
            d = new Date(d.getTime() + (h*60*60*1000))
        // }
        var output_time = d.toLocaleTimeString().toUpperCase().replace(/([\d]+:[\d]+):[\d]+(\s\w+)/g, "$1$2");
        var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if( this.getFromLocalStorage("lang") === "ar" ){
          month = ['يناير','فبراير','مارس','ابريل','مايو','يونيو','يوليو','أغسطس','سبتمبر','أكتوبر','نوفمبر','ديسمبر'];
            output_time = output_time.replace(/am/g,"صباحا");
            output_time = output_time.replace(/pm/g,"مساءً");
        }
        var output_date =  d.getDate() +" "+ month[d.getMonth()]  +" "+ d.getFullYear() + ', ' + output_time;
        
        //return d.toISOString().split('T')[0]
        return output_date
    }
    getTimeZoneOffset(timeZone) {
        let date = new Date()
        // Abuse the Intl API to get a local ISO 8601 string for a given time zone.
        const options = {timeZone, calendar: 'iso8601', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false};
        const dateTimeFormat = new Intl.DateTimeFormat(undefined, options);
        const parts = dateTimeFormat.formatToParts(date);
        const map = new Map(parts.map(x => [x.type, x.value]));
        const year = map.get('year');
        const month = map.get('month');
        const day = map.get('day');
        const hour = map.get('hour');
        const minute = map.get('minute');
        const second = map.get('second');
        const ms = date.getMilliseconds().toString().padStart(3, '0');
        const iso = `${year}-${month}-${day}T${hour}:${minute}:${second}.${ms}`;
      
        // Lie to the Date object constructor that it's a UTC time.
        const lie = new Date(iso + 'Z');
        // Return the difference in timestamps, as minutes
        // Positive values are West of GMT, opposite of ISO 8601
        // this matches the output of `Date.getTimeZoneOffset`
        return (lie - date) / 60 / 60 / 1000;
    }
    boolName(bool){
        let str = bool;
        if(this.getFromLocalStorage("lang") === "ar"){
            if(bool == true || bool == "true"){
                str = "نعم"
            }else{
                str = "لا"
            }
        }else {
            if(bool == true || bool == "true"){
                str = "Yes"
            }else{
                str = "No"
            }
        }
        return str
    }
    isLiveName(bool){
        let str = bool;
        if(this.getFromLocalStorage("lang") === "ar"){
            if(bool == true || bool == "true"){
                str = "نشط"
            }else{
                str = "اختبار"
            }
        }else {
            if(bool == true || bool == "true"){
                str = "Live"
            }else{
                str = "Test"
            }
        }
        return str

    }
    getTenure(input) {
        if (typeof input === 'object' && input.month) {
            return input.month;
        } else if (typeof input === 'number' || typeof input === 'string') {
            return input;
        } else {
            return "-"; 
        }
    }
    getLastName(fullName){
        let str = fullName.trim()
        let array = str.split(' ');
        return array[array.length - 1];
    }
    getFirstName(fullName){
        let str = fullName.trim()
        let array = str.split(' ');
        return array[0];
    }
    getAccountType(){
        return this.getFromLocalStorage("type");
    }
    convertAmount(amount,currency){
        if(amount === "-"){
            amount = 0;
        }
        return dependencies.numberWithCommas((amount/1).toFixed(2)) + " " + this.currencyName(currency);
    }
    convertAmountCentsNoCurr(amount,currency){
        if (currency === "JOD" || currency === "OMR" || currency === "KWD") {
            return (amount * 1000);
        } else {
            return (amount * 100);
        }
    }
    convertAmountCentsWithRounding(amount, currency) {
        const amountInCents = this.convertAmountCentsNoCurr(amount, currency);
        
        return Math.round(amountInCents);
    }
    convertAmountWithFixedDigits(amount, currency) {        
        if (currency === "JOD" || currency === "OMR" || currency === "KWD") {
            return amount.toFixed(3);  
        } else {
            return amount.toFixed(2);  
        }
    }
    convertCentsAmountFull(amount,currency){
        if(amount === "-"){
            amount = 0;
        }
        if(currency === "JOD"){
            return dependencies.numberWithCommas(amount/1000) + " " + this.currencyName(currency)
        }else{
            return dependencies.numberWithCommas(amount/100) + " " + this.currencyName(currency)
        }
    }
    convertCentsAmountFullNoCurr(amount){
        return dependencies.numberWithCommas(amount/100)
    }
    getCurrentLang =() =>{
        if (this.getFromLocalStorage("lang")) {
            return this.getFromLocalStorage("lang")
        } else {
            return "en"
        }
    }
    convertCentsAmount(amount,currency){
        if(currency === "JOD"){
            return (amount/1000) + " " + this.currencyName(currency)
        }else{
            return (amount/100) + " " + this.currencyName(currency)
        }
    }
    convertCentsAmountNoCurr(amount,currency){
        if(currency === "JOD"){
            return (amount/1000)
        }else{
            return (amount/100)
        }
    }
    viewIntegrationsPerm(){
        return (this.getFromLocalStorage("permNames")).includes("can view payment integration");
    }
    getCents (currency){
        if(currency === "JOD" || currency === "OMR" || currency === "KWD"){
            return 1000
        }else{
            return 100
        }
    }
    getAmplitudeApiKey (){
        let key = "af23be78b297f530fea828fee3860a8f";

        return key;
    }
}

export let dependencies = new Dependencies();
