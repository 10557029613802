import axios from "axios";
import {appConfig} from "./AppConfig";

class HttpClient {

    get(path, config, successCb, errorCb) {
        let url = appConfig.getServerUrl() + path;
        //config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        axios.get(url, config)
            .then(successCb)
            .catch(errorCb);
    }
    get_bills(path, config, successCb, errorCb) {
        let url = appConfig.getBillsServerURL() + path;
        //config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        axios.get(url, config)
            .then(successCb)
            .catch(errorCb);
    };
    get_full_url(domain, path, config, successCb, errorCb) {
        let url = domain + path;
        //config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        axios.get(url, config)
            .then(successCb)
            .catch(errorCb);
    }
    get_growth(path, config, successCb, errorCb) {
        let url = appConfig.getGrowthServerUrl() + path;
        //config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        axios.get(url, config)
            .then(successCb)
            .catch(errorCb);
    }
    post(path, config, data, successCb, errorCb) {
        let url = appConfig.getServerUrl() + path;
        //config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        axios.post(url, data, config)
            .then(successCb)
            .catch(errorCb);
    }
    post_full_url(domain, path, config, data, successCb, errorCb) {
        let url = domain + path;
        //config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        axios.post(url, data, config)
            .then(successCb)
            .catch(errorCb);
    }
    post_growth(path, config, data, successCb, errorCb) {
        let url = appConfig.getGrowthServerUrl() + path;
        //config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        axios.post(url, data, config)
            .then(successCb)
            .catch(errorCb);
    }
    delete(path, config, successCb, errorCb) {
        let url = appConfig.getServerUrl() + path;
        //config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        axios.delete(url, config)
            .then(successCb)
            .catch(errorCb);
    }
  delete_full_url(domain, path, config, successCb, errorCb) {
    let url = domain + path;
    config.timeout= 100 * 1000;
    axios.delete(url, config)
      .then(successCb)
      .catch(errorCb);
  }
    put(path, config, data, successCb, errorCb) {
        let url = appConfig.getServerUrl() + path;
        //config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        axios.put(url, data, config)
            .then(successCb)
            .catch(errorCb);
    }
  put_full_url(domain, path, config, data, successCb, errorCb) {
    let url = domain + path;
    config.timeout= 100 * 1000;
    axios.put(url, data, config)
      .then(successCb)
      .catch(errorCb);
  }
    // Onboarding
    onboarding_get(path, config, successCb, errorCb) {
        let url = appConfig.getOnboardingServerUrl() + path;
        //config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        axios.get(url, config)
            .then(successCb)
            .catch(errorCb);
    }
    onboarding_post(path, config, data, successCb, errorCb) {
        let url = appConfig.getOnboardingServerUrl() + path;
        //config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        axios.post(url, data, config)
            .then(successCb)
            .catch(errorCb);
    }
    onboarding_patch(path, config, data, successCb, errorCb) {
        let url = appConfig.getOnboardingServerUrl() + path;
        //config.headers["Cache-Control"]= "no-cache";
        config.timeout= 100 * 1000;
        axios.patch(url, data, config)
            .then(successCb)
            .catch(errorCb);
    }
}

export let httpClient = new HttpClient();
