export default function getParams(url) {
  var params = {};
  var parser = document.createElement("a");
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    let firstEquelIndex = vars[i].indexOf("=");
    var pair = [
      vars[i].slice(0, firstEquelIndex),
      vars[i].slice(firstEquelIndex + 1)
    ];
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}
