import React,{useState} from 'react'
import { httpClient } from '../../../tools/HttpClient';
import Waiting from './../../Waiting/waiting';
// VForm
import VForm from 'react-validation/build/form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VButton from 'react-validation/build/button';
// Images
import Paymob from './../../../assets/img/brand/logo.svg';
// Style
import './bulkDisbursement.scss';

function bulkDisbursement() {

  const [waiting, setWaiting] = useState(false)
  const [offer, setOffer] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const token = new URLSearchParams(window.location.search).get("token");

  const handleOfferRequest = (e) => {
    setWaiting(true)
    e.preventDefault();
    //send request
    const dataObj = { 
        "amount_cents": offer
      };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": token
      }
    };
    const data = JSON.stringify(dataObj);
    httpClient.post(
      "/api/acceptance/billspromo",
      config,
      data,
      (success) => {
        setWaiting(false)
        setConfirm(true)
      },
      (error) => {
        setWaiting(false)
        if (error.response.data.detail){
          toast.error(error.response.data.detail, {
            theme: "colored",
            position: "top-center",
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 5000,
          });
        }else{
          toast.error("Somethig went wrong! please try again later.", {
            theme: "colored",
            position: "top-center",
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 5000,
          });
        }
      }
    );
  }
  const renderLayout = () => {
    return(
      <div className="sms_opt">
        <img src={Paymob} className="logo img-fluid" />
            {confirm ? renderConfirmationMessage() : renderContent()}
            <div className="social_links">
                <a target="_blank" href="https://www.facebook.com/PaymobCompany"><i class="fa fa-facebook fa-lg" aria-hidden="true"></i></a>
                <a target="_blank" href="https://www.youtube.com/channel/UCCObLW7LKRyu1qF3bA7MCWA"><i class="fa fa-youtube fa-lg" aria-hidden="true"></i></a>
                <a target="_blank" href="https://www.instagram.com/paymobcompany"><i class="fa fa-instagram fa-lg" aria-hidden="true"></i></a>
                <a target="_blank" href="https://www.linkedin.com/company/paymobcompany"><i class="fa fa-linkedin fa-lg" aria-hidden="true"></i></a>
            </div>
            <p className="copy_right">© Paymob 2021 All Rights Reserved.</p>
        </div>
    )
}

const renderContent = () => {
  return(
    <div className="steps_container">
      {waiting?
        <Waiting height="200px" />
      :
        <>
          <h1>خدمة باقات شحن رصيد الخدمات</h1>
          <p>أختر من الباقات التالية</p>
          <VForm onSubmit={handleOfferRequest}>
            <div className='options'>
              <div type="button" className={` ${offer === 100000 && 'active'} option`} onClick={()=>setOffer(100000)}>1000</div>  
              <div type="button" className={` ${offer === 150000 && 'active'} option`} onClick={()=>setOffer(150000)}>1500</div>  
              <div type="button" className={` ${offer === 200000 && 'active'} option`} onClick={()=>setOffer(200000)}>2000</div>  
              <div type="button" className={` ${offer === 300000 && 'active'} option`} onClick={()=>setOffer(300000)}>3000</div>  
            </div>
            {offer ? <VButton type="submit">تأكيد</VButton> :  <button type="submit" disabled >تأكيد</button>}
          </VForm>
        </>
      }
    </div>
  ) 
}
 
const renderConfirmationMessage = () => {
  return(
    <div className="confirmation_message">
      <p>سوف يتم شحن رصيدك للخدمات بقيمة {offer/100} ج م صالح لمدة 14 يوم.

        للسداد يمكنك استخدام الكارت الخاص بك او عن طريق رصيدك في اكسبت او من خلال مسئول الحساب الخاص بك.

        لمزيد من المعلومات، اتصل بـ   19079.

        باي موب متفوتش بيعه.
      </p>
      <div>
        <span>Powered by</span>
        <img src={Paymob} className="img-fluid" />
      </div>
    </div>
  )
}
  return (
      <div className='sms_offers_container'>
        <ToastContainer limit={3} />
        {renderLayout()}
      </div>
  )
}

export default bulkDisbursement