import parseDate from "./parseDate";

export default function getNextDayDate(date) {
  var nextDay = parseDate(date);
  if (nextDay === "Invalid Date") {
    return "Invalid Date";
  }
  nextDay.setDate(nextDay.getDate() + 1);
  var nextDayMonth = nextDay.getMonth() + 1;
  return (nextDay.getFullYear() + "-" +  nextDayMonth.toString().padStart(2, "0") + "-" + nextDay.getDate().toString().padStart(2, "0"));
}
