import { combineReducers } from 'redux';
import mainReducer from './Main/main.reducer';
import flashReducer from './Flash/flash.reducer';
import tablesReducer from './Tables/tables.reducer';
import onboardingReducer from './Onboarding/onboarding.reducer';
import homeChartsReducer from './HomeCharts/homeCharts.reducer';
import checkoutCustomizationReducer from './CheckoutCustomization/checkoutCustomization.reducer';

    const rootReducer = combineReducers({
        main: mainReducer,
        tables: tablesReducer,
        flash: flashReducer,
        onboarding: onboardingReducer,
        homeCharts: homeChartsReducer,
        checkoutCustomization: checkoutCustomizationReducer
    });

export default rootReducer;