var cityStateDict = {"10th of Ramdan City": "Al Sharqia",
"15th of May City": "Cairo",
"1st Settlement": "Cairo",
"3rd Settlement": "Cairo",
"5th Settlement": "Cairo",
"6th of October": "Giza",
"Abaseya": "Cairo",
"Abdeen": "Cairo",
"Abdo Basha": "Cairo",
"Abees": "Alexandria",
"Abnoub": "Asyut",
"Abo Korkas": "Al Meniya",
"Abo Sultan": "Ismailia",
"Abou Al Matamer": "Al Beheira",
"Abou Rawash": "Giza",
"Abou Teag": "Asyut",
"Abu Hammad": "Al Sharqia",
"Abu Hummus": "Al Beheira",
"Abu Kbeer": "Al Sharqia",
"Abu Keer": "Alexandria",
"Abu Simbel": "Aswan",
"Abu Swer": "Ismailia",
"Abu Tesht": "Qena",
"Abu Zaabal": "Qalyubia",
"Aga": "Al Daqahliya",
"Agouza": "Giza",
"Ahnaseaa": "Bani Souaif",
"Ain Al Sukhna": "Suez",
"Ain Shams": "Cairo",
"Akhmem": "Sohag",
"Al A'mriah": "Alexandria",
"Al Adabya": "Suez",
"Al Arish": "North Sinai",
"Al Azhar": "Cairo",
"Al Barageel": "Giza",
"Al Beheira": "Al Beheira",
"Al Bitash": "Alexandria",
"Al Daher": "Cairo",
"Al Daqahliya": "Al Daqahliya",
"Al Delengat": "Al Beheira",
"Al Fayoum": "Al Fayoum",
"Al Gharbia": "Al Gharbia",
"Al Hasiniya": "Al Sharqia",
"Al Ibrahimiya": "Al Sharqia",
"Al Kalaa": "Cairo",
"Al Kasaseen": "Ismailia",
"Al Kasr Al Einy": "Cairo",
"Al Khanka": "Qalyubia",
"Al Kom Al Ahmer": "Giza",
"Al Mahala Al Kobra": "Al Gharbia",
"Al Mahmoudiyah": "Al Beheira",
"Al Manashi": "Giza",
"Al Mansoura": "Al Daqahliya",
"Al Matareya": "Cairo",
"Al Meniya": "Al Meniya",
"Al Moatamadia": "Giza",
"Al Monib": "Giza",
"Al Monufia": "Al Monufia",
"Al Moski": "Cairo",
"Al Nahda Al Amria": "Alexandria",
"Al Nobariah": "Giza",
"Al Rahmaniyah": "Al Beheira",
"Al Rehab": "Cairo",
"Al Riadh": "Kafr El Sheikh",
"Al Sad Al Aali": "Aswan",
"Al Saf": "Giza",
"Al Salam City": "Cairo",
"Al Salhiya Al Gedida": "Al Sharqia",
"Al Shareaa Al Gadid": "Qalyubia",
"Al Sharqia": "Al Sharqia",
"Al Soyof": "Alexandria",
"Al Suez": "Suez",
"Al Wahat": "Giza",
"Al Zarkah": "Damietta",
"Al Zeitoun": "Cairo",
"Alexandria": "Alexandria",
"Almaza": "Cairo",
"Alsanta": "Al Gharbia",
"Amiria": "Cairo",
"Aossim": "Giza",
"Armant Gharb": "Luxor",
"Armant Sharq": "Luxor",
"Asafra": "Alexandria",
"Ashmoon": "Al Monufia",
"Assuit Elgdeda": "Asyut",
"Aswan": "Aswan",
"Asyut": "Asyut",
"Ataka District": "Suez",
"Atsa": "Al Fayoum",
"Awaied-Ras Souda": "Alexandria",
"Awlad Saqr": "Al Sharqia",
"Azarita": "Alexandria",
"Badr City": "Cairo",
"Badrashin": "Giza",
"Bahteem": "Qalyubia",
"Balteem": "Kafr El Sheikh",
"Bangar EL Sokar": "Alexandria",
"Banha": "Qalyubia",
"Bani Mazar": "Al Meniya",
"Bani Souaif": "Bani Souaif",
"Basateen": "Cairo",
"Basyoon": "Al Gharbia",
"Bebaa": "Bani Souaif",
"Bela": "Kafr El Sheikh",
"Belbes": "Al Sharqia",
"Belqas": "Al Daqahliya",
"Berak Alkiaam": "Giza",
"Berket Al Sabei": "Al Monufia",
"Bolak Al Dakrour": "Giza",
"Borg El Arab": "Alexandria",
"Borollos": "Kafr El Sheikh",
"Cairo": "Cairo",
"City Center": "Alexandria",
"Cornish Al Nile": "Cairo",
"Dahab": "South Sinai",
"Damanhour": "Al Beheira",
"Damietta": "Damietta",
"Dar Al Salam": "Cairo",
"Dar Elsalam": "Sohag",
"Darb Negm": "Al Sharqia",
"Dayrout": "Asyut",
"Dekernes": "Al Daqahliya",
"Dermwas": "Al Meniya",
"Deshna": "Qena",
"Desouq": "Kafr El Sheikh",
"Dokki": "Giza",
"Down Town": "Cairo",
"Draw": "Aswan",
"EL Marg": "Cairo",
"Ebshoy": "Al Fayoum",
"Edfina": "Al Beheira",
"Edfo": "Aswan",
"Edko": "Al Beheira",
"El Aagamen": "Al Fayoum",
"El Alamein": "Matrooh",
"El Arbeen District": "Suez",
"El Badari": "Asyut",
"El Borg El Kadem": "Alexandria",
"El Dabaa": "Matrooh",
"El Fashn": "Bani Souaif",
"El Ghnayem": "Asyut",
"El Herafieen": "Cairo",
"El Kanater EL Khayrya": "Qalyubia",
"El Karnak": "Luxor",
"El Kharga": "New Valley",
"El Khsos": "Qalyubia",
"El Klabsha": "Aswan",
"El Korimat": "Bani Souaif",
"El Korna": "Luxor",
"El Monshah": "Sohag",
"El Nubariyah": "Al Beheira",
"El Oboor": "Qalyubia",
"El Qalag": "Qalyubia",
"El Qusya": "Asyut",
"El Shorouk": "Cairo",
"El Sinblaween": "Al Daqahliya",
"El Tahrir": "Cairo",
"El Tal El Kebir": "Ismailia",
"El Wastaa": "Bani Souaif",
"El-Agamy": "Alexandria",
"Eladwa": "Al Meniya",
"Elbalyna": "Sohag",
"Elfath": "Asyut",
"Elganaien District": "Suez",
"Elsalhia Elgdida": "Ismailia",
"Esnaa": "Luxor",
"Etay Al Barud": "Al Beheira",
"Ezbet El Nakhl": "Cairo",
"Faisal": "Giza",
"Faqous": "Al Sharqia",
"Fareskor": "Damietta",
"Farshoot": "Qena",
"Fayed": "Ismailia",
"Fooh": "Kafr El Sheikh",
"Fustat": "Cairo",
"Garden City": "Cairo",
"Gerga": "Sohag",
"Gesr Al Suez": "Cairo",
"Ghamrah": "Cairo",
"Ghena": "Sohag",
"Giza": "Giza",
"Glem": "Alexandria",
"Gouna": "Red Sea",
"Hadayek Al Qobah": "Cairo",
"Hadayek Al Zaiton": "Cairo",
"Hadayek Helwan": "Cairo",
"Hadayek Maadi": "Cairo",
"Hadayeq El Ahram": "Giza",
"Hamool": "Kafr El Sheikh",
"Haram": "Giza",
"Hawamdya": "Giza",
"Hehya": "Al Sharqia",
"Heliopolis": "Cairo",
"Helmeya": "Cairo",
"Helmiet Elzaitoun": "Cairo",
"Helwan": "Cairo",
"Hosh Issa": "Al Beheira",
"Hurghada": "Red Sea",
"Imbaba": "Giza",
"Ismailia": "Ismailia",
"Kafer Abdou": "Alexandria",
"Kafr Alziat": "Al Gharbia",
"Kafr El Dawwar": "Al Beheira",
"Kafr El Sheikh": "Kafr El Sheikh",
"Kafr Saad": "Damietta",
"Kafr Saqr": "Al Sharqia",
"Kafr Shokr": "Qalyubia",
"Katamiah": "Cairo",
"Kerdasa": "Giza",
"Khorshid": "Alexandria",
"Kit Kat": "Giza",
"Kofooer Elniel": "Al Fayoum",
"Kom Hamadah": "Al Beheira",
"Kom Ombo": "Aswan",
"Luran": "Alexandria",
"Luxor": "Luxor",
"Maadi Degla": "Cairo",
"Maamora": "Alexandria",
"Madinty": "Cairo",
"Mahtet El-Raml": "Alexandria",
"Malawi": "Al Meniya",
"Mandara": "Alexandria",
"Manflout": "Asyut",
"Manial": "Giza",
"Manial Al Rodah": "Cairo",
"Manshaa Abdalla": "Al Fayoum",
"Manshaa Elgamal": "Al Fayoum",
"Manshia": "Alexandria",
"Mansoureya": "Giza",
"Manzala": "Al Daqahliya",
"Maragha": "Sohag",
"Markaz Naser": "Aswan",
"Marsa Alam": "Red Sea",
"Marsa Matrooh": "Matrooh",
"Masaken Sheraton": "Cairo",
"Mashtool Al Sooq": "Al Sharqia",
"Matai": "Al Meniya",
"Matrooh": "Matrooh",
"Meet Ghamr": "Al Daqahliya",
"Meet Nama": "Qalyubia",
"Menit El Nasr": "Al Daqahliya",
"Meniya Alqamh": "Al Sharqia",
"Menoof": "Al Monufia",
"Metobas": "Kafr El Sheikh",
"Mghagha": "Al Meniya",
"Miami": "Alexandria",
"Minya": "Al Meniya",
"Mirage City": "Cairo",
"Mohandessin": "Giza",
"Mokattam": "Cairo",
"Mostorod": "Qalyubia",
"Muntazah": "Alexandria",
"Nabroo": "Al Daqahliya",
"Naga Hamadi": "Qena",
"Naqada": "Qena",
"Naser": "Bani Souaif",
"Nasr City": "Cairo",
"Nasr Elnoba": "Aswan",
"New Bani Souaif": "Bani Souaif",
"New Cairo": "Cairo",
"New Damietta": "Damietta",
"New El Marg": "Cairo",
"New Fayoum": "Al Fayoum",
"New Maadi": "Cairo",
"New Nozha": "Cairo",
"New Valley": "New Valley",
"Neweibaa": "South Sinai",
"Nfeesha": "Ismailia",
"North Sinai": "North Sinai",
"Om Bayoumi": "Qalyubia",
"Omraneya": "Giza",
"Orabi": "Qalyubia",
"Port Fouad": "Port Said",
"Port Said": "Port Said",
"Qaha": "Qalyubia",
"Qalyoob": "Qalyubia",
"Qalyubia": "Qalyubia",
"Qantara Gharb": "Ismailia",
"Qantara Sharq": "Ismailia",
"Qeleen": "Kafr El Sheikh",
"Qena": "Qena",
"Qism el Giza": "Giza",
"Qoos": "Qena",
"Qotoor": "Al Gharbia",
"Qouseir": "Red Sea",
"Quesna": "Al Monufia",
"Ramsis": "Cairo",
"Ras El Bar": "Damietta",
"Ras Ghareb": "Red Sea",
"Rashid": "Al Beheira",
"Red Sea": "Red Sea",
"Rod El Farag": "Cairo",
"Roshdy": "Alexandria",
"Sadat City": "Al Monufia",
"Safaga": "Red Sea",
"Saft El Laban": "Giza",
"Sahel Selim": "Asyut",
"Saint Catherine": "South Sinai",
"Sakiat Mekki": "Giza",
"Samaloot": "Al Meniya",
"Samanood": "Al Gharbia",
"San Stefano": "Alexandria",
"Sanhoor": "Al Fayoum",
"Saqatlah": "Sohag",
"Sayeda Zeinab": "Cairo",
"Sedi Bisher": "Alexandria",
"Sedi Gaber": "Alexandria",
"Sedi Kreir": "Alexandria",
"Seedy Salem": "Kafr El Sheikh",
"Serfa": "Asyut",
"Sersenaa": "Al Fayoum",
"Shabramant": "Giza",
"Sharm Al Sheikh": "South Sinai",
"Sheben Alkanater": "Qalyubia",
"Shebin El Koom": "Al Monufia",
"Sheikh Zayed": "Giza",
"Shohada": "Al Monufia",
"Shoubra Alkhema": "Qalyubia",
"Shrbeen": "Al Daqahliya",
"Shubra": "Cairo",
"Shubrakhit": "Al Beheira",
"Sidi Abdel Rahman": "Matrooh",
"Smart Village": "Giza",
"Smostaa": "Bani Souaif",
"Smouha": "Alexandria",
"Sohag": "Sohag",
"Sonores": "Al Fayoum",
"South Sinai": "South Sinai",
"Sporting": "Alexandria",
"Srabioom": "Ismailia",
"Stanly": "Alexandria",
"Suez": "Suez",
"Taba": "South Sinai",
"Tahta": "Sohag",
"Tala": "Al Monufia",
"Talkha": "Al Daqahliya",
"Tameaa": "Al Fayoum",
"Tanta": "Al Gharbia",
"Tema": "Sohag",
"Tirsa": "Giza",
"Tookh": "Qalyubia",
"Toor Sinai": "South Sinai",
"Wadi Al Natroun": "Al Beheira",
"Warraq": "Giza",
"Youssef Sadek": "Al Fayoum",
"Zakazik": "Al Sharqia",
"Zamalek": "Cairo",
"Zefta": "Al Gharbia",
"Zezenya": "Alexandria",
"Zohoor District": "Port Said"}
export default cityStateDict;