import React, { Component } from 'react';
import VForm from 'react-validation/build/form';
import StepZilla from "react-stepzilla";
import "react-stepzilla/src/css/main.css";
import {httpClient} from '../../../tools/HttpClient';
import { auth } from '../../../tools/Auth';
import StepOne from './Steps/StepOne';
import StepTwo from './Steps/StepTwo';
import BusinessInfo from './Steps/BusinessInfo';
import SucessContainer from './Steps/sucessContainer';
import states from '../PopUp/Steps/countryState/statesArr';
import country_arr from '../PopUp/Steps/countryState/countrysArr';
import SignHeader from '../../SignHeader/SignHeader';
import Progressbar from './Progressbar/Progressbar';
import './Steps/Steps.scss';
import { dependencies } from '../../../tools/Dependencies';
import * as amplitude from "@amplitude/analytics-browser";

const STEPS = {
  STEP1: 1,
  STEP2: 2,
  STEP3: 3
}
class Popup extends Component{
state={
  disableNext: true,
  disableBack: true,
  currentStep: 1,
  progressbar: 100 / 3,
  business:'',
  legal_papers: false,
  commercial_register: false,
  tax_card:false,
  integration:false,
  standalone :false,
  social_media:false,
  retail:false,
  allSelection:false,
  waiting:false,
  businessPath: '/api/auth/business_information',
  questionSuccess: false,
  brandName:'',
  description:'',
  sectorOption: '',
  sectorValue:'',
  industryOption:'',
  industryValue:'',
  street:'',
  buildNum:'',
  floor:'',
  website:'',
  facebook:'',
  instagram:'',
  country: '1',
  countryName: 'Egypt',
  state: '',
  stateName: '',
  city: '',
  cityName: '',
  errorMesage:'',
  error:false,
  stepperMessage:''
}
  standaloneChangeHandle=()=>{
    this.setState({standalone: !this.state.standalone},()=>{
      this.checkNextBackDisable()
    })
  }

  HandleStepSubmit=(e)=>{
    const dataObj = {
    "business":this.state.business,
    "legal_papers": this.state.legal_papers,
    "commercial_register": this.state.commercial_register,
    "tax_card":this.state.tax_card,
    'integration':this.state.integration,
    "standalone" :this.state.standalone,
    "social_media":this.state.social_media,
    "retail":this.state.retail
    }
    let data = JSON.stringify(dataObj);

    let config = {
      headers: {
        //"Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "Accept":"application/json",
        "Authorization": "Bearer " + auth.getMerchantToken(),
      },
    }
    let errorValue=[];
    httpClient.post(
        this.state.businessPath,
        config,
        data,
        (resp )=> {
          dependencies.addToLocalStorage("comapnyType", resp.data.business)
          dependencies.addToLocalStorage("filledAnswer", true)
          dependencies.getFromLocalStorage("filled_business_data", false);
          this.setState({questionSuccess :true});
          setTimeout(()=>{
            window.location.reload();
          },3000)
        },
      (error) => {
        console.log(error)
        if (error.response) {
          if (error.response.status === 400) {
            errorValue.push(Object.keys(error.response.data))
            this.setState({ error: true, errorMessage: errorValue });
          } else if (error.response.status === 401) {
            this.setState({ logout: true });
          } else {
            this.setState({ errorMessage: error.response.statusText });
          }
        } else {
          // network error
          this.setState({ errorMessage: this.props.Text.networkError });
        }
      }
    )
  }
  HandleQaSubmit=(e)=>{
    this.setState({waiting: true});
    const dataObj = {
    "business":this.state.business,
    "legal_papers": this.state.legal_papers,
    "commercial_register": this.state.commercial_register,
    "tax_card":this.state.tax_card,
    'integration':this.state.integration,
    "retail":this.state.retail,
    "standalone" :this.state.standalone,
    "social_media":this.state.social_media,
    "business_description":this.state.description,
    "brand_name":this.state.brandName,
    "sector": this.state.sectorOption,
    "industry":this.state.industryOption,
    'address_governorate': this.state.stateName,
    'address_district': this.state.cityName,
     "address_street":this.state.street,
    "address_building":this.state.buildNum,
     "address_floor":this.state.floor,
     "website_url":this.state.website,
     "facebook_url":this.state.facebook,
     "instagram_url":this.state.instagram,

  };
   let data = JSON.stringify(dataObj);

  let config = {
    headers: {
      //"Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept":"application/json",
      "Authorization": "Bearer " + auth.getMerchantToken(),
    },
  }
  let errorValue=[];
  httpClient.post(
      this.state.businessPath,
      config,
      data,
      (response )=> {
        amplitude.track('onboarding_business_info');

        dependencies.addToLocalStorage("filledAnswer", true)
        dependencies.addToLocalStorage("filled_business_data", true);
        this.setState({questionSuccess :true});
        setTimeout(()=>{
          if(dependencies.getFromLocalStorage("flash_redirect") && dependencies.getFromLocalStorage("flash_redirect") == true ){
            dependencies.deleteFromLocalStorage("flash_redirect");
            window.location.pathname = "/portal2/" + dependencies.getFromLocalStorage("lang") + "/flash/integration";
          }else{
            window.location.reload();
          }
        },3000)
       
      },
    (error) => {
      if (error.response) {
        if (error.response.status === 400) {
          errorValue.push(Object.keys(error.response.data))
          this.setState({ error: true, errorMessage: errorValue });
        } else if (error.response.status === 401) {
          this.setState({ logout: true });
        } else {
          this.setState({ errorMessage: error.response.statusText });
        }
      }else{
        // network error
        this.setState({ errorMesage: this.props.Text.networkError});
      }
    }
  )
  e.preventDefault();
}
individualBusinessChange =(e)=> {
  this.setState({business: e.target.value},()=>{
    this.checkNextBackDisable()
  });
}

  selectAllChange =()=>{
  this.setState({
    allSelection:!this.state.allSelection,
    integration: !this.state.allSelection,
   retail: !this.state.allSelection ,
   standalone: !this.state.allSelection,
  },()=>{
    this.checkNextBackDisable()
  });
}; 
SocialHandleChange=() =>{
  this.setState({
    integration: !this.state.integration 
  },()=>{
    this.checkNextBackDisable()
  });
}
retailHandleChange=()=>{
  this.setState({
    retail: !this.state.retail 
  },()=>{
    this.checkNextBackDisable()
  });
}
brandNameChange=(e)=>{
  this.setState({brandName: e.target.value})
}
descriptionChange=(e)=>{
  this.setState({description: e.target.value})
}
instagramChange=(e)=>{
this.setState({instagram: e.target.value})
}
facebookChange=(e)=>{
  this.setState({facebook:e.target.value})
}
websiteChange=(e)=>{
  this.setState({website: e.target.value})
}
floorChange=(e)=>{
  this.setState({floor: e.target.value})
}
buildNumChange=(e)=>{
  this.setState({buildNum: e.target.value})
}
streetChange=(e)=>{
  this.setState({street: e.target.value})
}
handleCountryChange=(e)=>{
  let countryName = country_arr[e.target.value - 1]
  this.setState({country: e.target.value, countryName: countryName,state: '', stateName: '', city: '', cityName: ''});
}
handleStateChange=(e)=> {
  if(e.target.value !== '-1'){
      let currentState = states[this.state.country].split("|")[e.target.value - 1];
      this.setState({state: e.target.value, stateName: currentState, city: '', cityName: ''});
  }else{
      this.setState({state: e.target.value, stateName: '', city: '', cityName: ''});
  }
}
handleStateChange2=(e)=> {
    this.setState({stateName: e.target.value});
}
handelCityChange=(e)=> {
  if(e.target.value !== '-1'){
      this.setState({city: 1, cityName: e.target.value});
  }else{
      this.setState({city: e.target.value});
  }
  
}
handleCityChange2=(e)=> {
  this.setState({cityName: e.target.value});
}
handleSectorChange=(e)=>{
this.setState({sectorOption: e.target.value})
}
handleIndustryChange=(e)=>{
this.setState({industryOption:e.target.value})
}


handleResetData=()=>{
  this.setState({
    brandName:'',
    description:'',
    instagram:'',
    facebook:'',
    website:'',
    floor:'',
    buildNum:'',
    street:'',
    industryOption:'',
    sectorOption:'',
    state: '',
  stateName: '',
  city: '',
  cityName: '',
  })
  dependencies.addToLocalStorage("filled_business_data", true)
  window.location.reload();
}
nextStep = () => {
  const eventName = this.state.currentStep === STEPS.STEP1 ? 'onboarding_how' : 'onboarding_where'
  amplitude.track(eventName);

  let nextStep = this.state.currentStep + 1;
  let nextProgressbar = this.state.progressbar + (100 / 3);
  let disableBack = false;
  if(nextStep === 1){disableBack = true}
  this.setState({currentStep: nextStep,progressbar: nextProgressbar},()=>{
    this.checkNextBackDisable()
  })
}
backStep = () => {
  if(this.state.currentStep > 1 && this.state.progressbar > 30){
    let backStep = this.state.currentStep - 1;
    let backProgressbar = this.state.progressbar - (100 / 3);
    let disableBack = false;
    if(backStep === 1){disableBack = true}
    this.setState({currentStep: backStep,progressbar: backProgressbar},()=>{
      this.checkNextBackDisable()
    })
  }
}
checkNextBackDisable = () => {
  let currentStep = this.state.currentStep;
  let disableBack = false;
  if(currentStep === 1){disableBack = true}
  //next
  let disableNext = !this.checkStepAnswered(currentStep);
  this.setState({disableBack: disableBack, disableNext: disableNext})
}
checkStepAnswered = (step) => {
  // console.log("check step :" + step)
  // console.log("standalone: " + this.state.standalone);
  // console.log("social_media: " + this.state.integration);
  // console.log("retail: " + this.state.retail);
  if(step === 1){
    if(this.state.business === ''){
      return false
    }else{
      return true
    }
  }else if(step === 2){
    if(this.state.standalone  || this.state.retail  || this.state.integration){
      return true
    }else{
      return false
    }
  }else if(step === 3){
    if(this.state.brandName !== ''  && this.state.sectorOption !== ''  && this.state.industryOption !== ''  && this.state.description !== ''){
      return true
    }else{
      return false
    }
  }else{
    return false
  }
}
renderStep = () => {
  if(this.state.currentStep === 1){
    return(
      <StepOne 
        business={this.state.business}
        Text={this.props.Text.Step}
        individualBusinessChange={this.individualBusinessChange}
      />
    )
  }else if(this.state.currentStep === 2){
    return(
      <StepTwo 
        standaloneChangeHandle={this.standaloneChangeHandle} 
        retailHandleChange={this.retailHandleChange}
        SocialHandleChange={this.SocialHandleChange}
        selectAllChange={this.selectAllChange}
        Text={this.props.Text.Step} 
        standalone={this.state.standalone}
        integration={this.state.integration}
        retail={this.state.retail}
        allSelection={this.state.allSelection}
        error={this.state.error}
        errorMessage={this.state.errorMessage}
        HandleStepSubmit={this.HandleStepSubmit}
        invalidMesage={this.state.invalidMesage}
        stepperMessage={this.state.stepperMessage}
      />
    )
  }else if(this.state.currentStep === 3){
    return(
      <BusinessInfo 
        HandleQaSubmit={this.HandleQaSubmit} 
        textSubmit={this.props.Text.submitBtn}
        Text={this.props.Text}
        brandName={this.state.brandName}
        description={this.state.description}
        sectorOption={this.state.sectorOption}
        industryOption={this.state.industryOption}
        street={this.state.street}
        buildNum={this.state.buildNum}
        floor={this.state.floor}
        website={this.state.website}
        facebook={this.state.facebook}
        instagram={this.state.instagram}
        brandNameChange={this.brandNameChange}
        descriptionChange={this.descriptionChange}
        instagramChange={this.instagramChange}
        facebookChange={this.facebookChange}
        websiteChange={this.websiteChange}
        floorChange={this.floorChange}
        buildNumChange={this.buildNumChange}
        streetChange={this.streetChange}
        HandleStepSubmit={this.HandleStepSubmit}
        handleIndustryChange={this.handleIndustryChange}
        handleSectorChange={this.handleSectorChange}
        handleDistrictChange={this.handleDistrictChange}
        handleGovernmentChange={this.handleGovernmentChange}
        handleSkipPopup={this.handleSkipPopup}
        country={this.state.country}
        countryName={this.state.countryName}
        state={this.state.state}
        stateName={this.state.stateName}
        city={this.state.city}
        cityName={this.state.cityName}
        handleStateChange={this.handleStateChange}
        handleStateChange2={this.handleStateChange2}
        handelCityChange={this.handelCityChange}
        handleCityChange2={this.handleCityChange2}
        error={this.state.error}
        errorMessage={this.state.errorMessage}
      />
    )
  }else{
    return(
      null
    )
  }
  
}
renderBody = () => {
  return(
    <div className={`popup-conatiner businessDataPopup  ${dependencies.getFromLocalStorage("lang") === 'ar' && "Ar"}`}>
      <SignHeader />
      <div className="popup-body">
          <div className="row">
            <div  className="col-lg-7 col-md-8 col-sm-10 col-xs-12" style={{margin: "auto"}} >
              <Progressbar value={this.state.progressbar + "%"}/>
              <div  className="Popup-modal">
              {this.state.questionSuccess ? 
                <SucessContainer         Text={this.props.Text} />
              : <div>
                  {this.renderStep()}
                  <div className="stepBtnDiv">
                    {this.state.currentStep !== 3 ?
                      <button onClick={this.nextStep} className="onboardingBtn primaryBtn" disabled={this.state.disableNext}>{this.props.Text.nextBtn}</button>
                    :
                      <button className="onboardingBtn primaryBtn" onClick={this.HandleQaSubmit} disabled={!this.checkStepAnswered(3)}>{this.props.Text.submitBtn}</button>
                    }
                    {this.state.currentStep !== 1 ?
                      <button onClick={this.backStep} className="backBtn" disabled={this.state.disableBack}><i className={`fa fa-angle-${dependencies.getFromLocalStorage("lang") ==='ar'? 'right': 'left'}`} aria-hidden="true"></i> {this.props.Text.previousBtn}</button>
                    :
                      null
                    }
                  </div>
                </div>
              }
              </div>
            </div>
          </div>
        
      </div>
    </div>
  );
}
render(){
  return (
    this.state.logout?
      auth.logout()
    :
      this.renderBody()
  );
  }
}
export default Popup;