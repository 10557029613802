import React from 'react'
import { dependencies } from '../../../../tools/Dependencies';
import validator from 'validator';
import TextFile from "./Validations.json"

let Text = TextFile[dependencies.getFromLocalStorage("lang")];

class Validations {

    // Required - every input is Required
    required = (value, props) => {
        if (props.isChanged) {
            if (!!props.id) {
                if (validator.isEmpty(value)) {
                    return <div className="alert alert-danger">{Text.requiredV}</div>;
                } else {
                    if (value === "") {
                        return <div></div>
                    }
                }
            }
        } else {
            if (value === "") {
                return <div></div>
            }
        }
    }
    // Others Business Industry validation
    othersIndustryV = (value, props) => {
        if (props.isChanged) {
            if (!!props.id) {
                if (validator.isEmpty(value)) {
                    return  <div className="alert alert-danger">
                                {Text.requiredV}
                            </div>;
                }
                else if (value[1] == " " || value[2] == " ") {
                    return  <div className="alert alert-danger">
                                {Text.othersBusinessIndustryV}
                            </div>;
                }
                else if (value.search(/^([A-Za-z\u0600-\u06FF\s])*$/)) {
                    return  <div className="alert alert-danger">
                                {Text.othersBusinessIndustryV}
                            </div>;
                }
                else if (value.length < 3) {
                    return  <div className="alert alert-danger">
                                {Text.othersBusinessIndustryLengthV}
                            </div>;
                } else {
                    if (value === "") {
                        return <div></div>
                    }
                }
            }
        } else {
            if (value === "") {
                return <div></div>
            }
        }
    }
    // URL validation
    urlV = (value, props) => {
        if (props.isChanged) {
            if (!!props.id) {
                if (validator.isEmpty(value)) {
                    return <div className="alert alert-danger">
                                {Text.requiredV}
                            </div>;
                }else if (!validator.isURL(value)) {
                    return  <div className="alert alert-danger">
                                {Text.urlV}
                            </div>;
                } else {
                    if (value === "") {
                        return <div></div>
                    }
                }
            }
        } else {
            if (value === "") {
                return <div></div>
            }
        }
    }
    // Account Holder Name validation
    accountHolderNameV = (value, props) => {
        if (props.isChanged) {
            if (!!props.id) {
                if (validator.isEmpty(value)) {
                    return <div className="alert alert-danger">
                                {Text.requiredV}
                            </div>;
                } else if (value.search(/^$|^[a-zA-Z]+ [a-zA-Z]+( [a-zA-Z]+)?( [a-zA-Z]+)?( +)?$/)) {
                    return <div className="alert alert-danger">
                                {Text.accountHolderNameV}
                            </div>;
                } else {
                    if (value === "") {
                        return <div></div>
                    }
                }
            }
        } else {
            if (value === "") {
                return <div></div>
            }
        }
    }
    // Bank Account Number validation
    bankAccountNumberV = (value, props) => {
        if (props.isChanged) {
            if (!!props.id) {
                if (validator.isEmpty(value)) {
                    return <div className="alert alert-danger">
                                {Text.bankAccountNumberRequired}
                            </div>;
                } else if (!validator.isNumeric(value)) {
                    return <div className="alert alert-danger">
                                {Text.bankAccountNumberV1}
                            </div>;
                } else if (value.length < 10 || value.length > 30) {
                    return <div className="alert alert-danger">
                                {Text.bankAccountNumberV2}
                            </div>;
                } else {
                    if (value === "") {
                        return <div></div>
                    }
                }
            }
        } else {
            if (value === "") {
                return <div></div>
            }
        }
    }
    // IBAN validation
    ibanV = (value, props) => {
        if (props.isChanged) {
            if (!!props.id) {
                if (validator.isEmpty(value)) {
                    return <div className="alert alert-danger">
                                {Text.requiredV}
                            </div>;
                }else if (value.search(/^([A-Za-z0-9]{29,34})*$/)) {
                    return <div className="alert alert-danger">
                                {Text.ibanV}
                            </div>;
                } else {
                    if (value === "") {
                        return <div></div>
                    }
                }
            } 
        } else {
            if (value === "") {
                return <div></div>
            }
        }
    }
}
export let validations = new Validations();