import { UPDATEONBOARDINGSTATE } from './onboarding.types';

const INITIAL_STATE = {
    // Public
        "step": 0,
        "publicWaiting": true,
        "publicError": false,
        "publicErrorMessage": "",
        "stepWaiting": false,
        "cancelModalOpen": false,
        "industryDataLoaded": false,
        "paymentSolutionsDataLoaded": false,
        "banksDataLoaded": false,
        "merchantDataLoaded": false,
        "merchantDocsLoaded": false,
    // Step (1) Business Info
        "businessType": "r",
        "businessIndustryList": [],
        "selectedIndustryIndex": "",
        "businessIndustry": null,
        "enterOtherIndustry": false,
        "otherBusinessIndustry": "",
        "onlinePresence": "",
    // Step (2) Payment Solutions
        "paymentSolutions": [],
        "paymentSolutionsArr": [],
        "posNoSelectError": false,
        "onlineNoSelectError": false,
        "servicesArr": [],
    // Step (3) Documents
        "idType": "nationalID",
        "IDFSideIMG": null,
        "IDBSideIMG": null,
        "passportIMG": null,
        "commercialRegisterIMG": null,
        "taxCardFSideIMG": null,
        "taxCardBSideIMG": null,
        "rentContractIMG": null,
        "utilityBillIMG": null,
        // PDF Files
        "idFrontFile": null,
        "idBackFile": null,
        "passportFile": null,
        "residencyEvidenceFile": null,
        "commercialRegisterFile": null,
        "taxCardFile": null,
        "posContractFile": null,
        // to know if the file is returned from backend or uploaded by user
        "idFileDownloaded": false,
        "idAlreadySent": false,
        "passportFileDownloaded": false,
        "passportAlreadySent": false,
        "residencyEvidenceFileDownloaded": false,
        "commercialRegisterFileDownloaded": false,
        "taxCardFileDownloaded": false,
        "posContractFileDownloaded": false,
    // Step (4) Settlements
        "settlementMethod": "",
        "banksList": [],
        "selectedBankIndex": "",
        "bankName": "",
        "bankAccountName": "",
        "bankAccountNumber": "",
        "ibanNumber": "",
        "phoneNumber": "",
        "phoneNumberError": false,
        "phoneNumberErrorMessage": "",
    // Step (5) Terms and Conditions
        "merchantType": null,
        "merchantTypeWaiting": true,
        "merchantTypeError": false,
        "merchantTypeErrorMessage": false,
        "confirmTerms": false
};

const onboarding_reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATEONBOARDINGSTATE:
            return {
                ...action.payload.newState
            };

        default: return state;
    }

};

export default onboarding_reducer;