import * as CheckoutCustomizationTypes from './checkoutCustomization.types';

const INITIAL_STATE = {
	brandingTab: {
		businessName: {
			value: null,
			error: null
		},
		businessLogo: {
			value: null,
			checkoutValue: null,
			error: null
		},
		address: {
			value: null,
			error: null
		},
		city: {
			value: null,
			error: null
		},
		phoneNumber: {
			value: null,
			error: null
		},
		selectedCountry: null,
		selectedCountryCode: null,
		accentColor: {
			value: null,
			error: null
		},
		actionColor: {
			value: null,
			error: null
		},
		font: {},
		style: {},
	},
	paymentTab: {
		viewType: null,
		payLater: false,
		paymentMethods: [],
		bnplMethods: [],
		xpayMethods: [],
		allPaymentMethods: { tasks: {}, columns: {}, columnOrder: [] },
		normalPaymentMethods: { tasks: {}, columns: {}, columnOrder: [] },
		bnplPaymentMethods: { tasks: {}, columns: {}, columnOrder: [] },
		showBilling: false,
		showItemData: false,
		showSaveCardOption: false,
		showTerms: false,
		termsUrl: {
			value: null,
			error: null
		},
	},
	postPaymentTab: {
		useCustomMessage: true,
		confirmationMessage: {
			value: null,
			error: null
		},
		confirmationOption: null,
		redirectionTimeout: null,
		redirectionError: null,
	},
	isDefault: false
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case CheckoutCustomizationTypes.INITIALIZE_CHECKOUT_STATE:
			return {
				...action.payload
			};
		case CheckoutCustomizationTypes.UPDATE_BRANDING_TAB:
			return {
				...state,
				brandingTab: { ...action.payload.newState },
			};
		case CheckoutCustomizationTypes.UPDATE_PAYMENT_TAB:
			return {
				...state,
				paymentTab: { ...action.payload.newState }
			};
		case CheckoutCustomizationTypes.UPDATE_POST_PAYMENT_TAB:
			return {
				...state,
				postPaymentTab: { ...action.payload.newState }
			};
		default:
			return state;
	}
}