class AppConfig {
  getServerUrl() {
    // STOP EDITING THIS FILE..
    if (window.location.hostname == "localhost") {
      //return "https://portal.weaccept.co";
      return "https://accept-alpha.paymob.com";
    } else {
      if (!window.location.port || window.location.port == "") {
        return "https://" + window.location.hostname;
      } else {
        return (
          "https://" + window.location.hostname + ":" + window.location.port
        );
      }
    }
  }
  getGrowthServerUrl() {
    // STOP EDITING THIS FILE..
    if (
      window.location.hostname == "localhost" ||
      window.location.hostname == "acceptstagingtest.paymobsolutions.com"
    ) {
      return "https://growthenginestaging.paymobsolutions.com";
    } else {
      return "https://growthengine.paymobsolutions.com";
    }
  }
  getOnboardingServerUrl() {
    // STOP EDITING THIS FILE..
    if (
      window.location.hostname == "localhost" ||
      window.location.hostname == "acceptstagingtest.paymobsolutions.com"
    ) {
      // Staging
      return "https://merchantappstaging.paymobsolutions.com";
    } else if (window.location.hostname == "accept-alpha.paymob.com") {
      // alpha
      return "https://merchantappstaging.paymobsolutions.com";
    } else if (window.location.hostname == "accept-dev.paymobsolutions.com") {
      // Pre-Prod
      return "https://merchantapppreprod-all.paymobsolutions.com";
    } else {
      // Prod
      return "https://merchantapp.paymobsolutions.com";
    }
  }

  getPayoutURL() {
    let payoutURL = "";
    if (window.location.hostname == "acceptstagingtest.paymobsolutions.com") {
      payoutURL = "https://payouts.paymobsolutions.com/?refresh=1";
    } else {
      payoutURL = "https://payouts.paymob.com/?refresh=1";
    }
    return payoutURL;
  };
  getBillsServerURL() {
    if (
      window.location.hostname == "localhost" ||
      window.location.hostname == "accept-alpha.paymob.com"
    ) {
      return 'https://stg-bills.paymobsolutions.com';
    } else {
      return "https://bills.paymobsolutions.com";
    }
  };
  getMerchantappServerUrl() {
    if (window.location.hostname == "localhost" || window.location.hostname == "accept-alpha.paymob.com") {
      return "https://merchantappstaging.paymobsolutions.com";
    } else {
      return "https://merchantapp.paymobsolutions.com";
    }
  }

  getPaymobShieldServerUrl() {
    // STOP EDITING THIS FILE..
    if (window.location.hostname == "localhost" || window.location.hostname == "accept-alpha.paymob.com") {
      return "https://stg-paymobshield.paymobsolutions.com";
    } else {
      return "https://paymobshield.paymobsolutions.com";
    }
  }

  getRiskCenterServerUrl() {
    if (window.location.hostname === "localhost") {
      return `https://accept-alpha.paymob.com/api/ecommerce/risk-center-proxy/send`;
    } else {
      return `https://${window.location.hostname}/api/ecommerce/risk-center-proxy/send`;
    }
  }
  getLeadsServerUrl() {
    if (
      window.location.hostname == "localhost" ||
      window.location.hostname == "accept-alpha.paymob.com"
    ) {
      return "https://crm-staging.paymobsolutions.com";
    } else {
      return "https://salesapp.paymobsolutions.com";
    }
  }
}

export let appConfig = new AppConfig();
